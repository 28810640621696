import React, { useState, useEffect } from "react"
import { useGatsbyAuth } from "gatsby-plugin-okta"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"

export default function IndexPage() {

  return (
    <Layout>
      <Header />
      <>
        <Seo title="Who We Are" />
        <div className="container project-gallery">
          <div className="who-we-are my-4 my-md-5">
            <h1 className="project-gallery-heading mars-blue mars-centra-bold-font border-left-mars-teal text-start my-3 my-md-4 ps-2">
              <b>Who We Are</b>
            </h1>
            <div className="row justify-content-center g-3">
              <div className="col-md-4 col-12">
                <div className="card h-100 overflow-hidden">
                  <div className="image-section">
                    <StaticImage
                      src="../images/cyber-security.png"
                      className="card-img-top"
                      alt="cyber-security"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body website-dev p-3 py-xxl-4 text-center">
                    <h4 className="card-title text-white mb-0 mx-lg-4 px-lg-2">
                      Website Development &amp; Support
                    </h4>
                    <p className="card-text text-white px-lg-2 py-3">
                      End-to-end website development and project management
                      services under managed service model for promotion/
                      campaigns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="card h-100 overflow-hidden">
                  <div className="image-section">
                    <StaticImage
                      src="../images/website.png"
                      className="card-img-top"
                      alt="cyber-security"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body tech-tools-consulting p-3 py-xxl-4 text-center">
                    <h4 className="card-title text-white mb-0 mx-lg-4 px-lg-2">
                      Technology &amp; Tools Consulting
                    </h4>
                    <p className="card-text text-white px-lg-2 py-3">
                      Latest technology assessment and recommendations for your
                      requirements aligned to Mars standards and guidelines.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="card h-100 overflow-hidden">
                  <div className="image-section">
                    <StaticImage
                      src="../images/platform.png"
                      className="card-img-top"
                      alt="cyber-security"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body security-compliance p-3 py-xxl-4 text-center">
                    <h4 className="card-title text-white mb-0 mx-lg-4 px-lg-2">
                      Security &amp; Compliance
                    </h4>
                    <p className="card-text text-white px-lg-2 py-3">
                      Development methodology compliant with regulations such as
                      GDPR, CCPA but also Mars Security guidelines on ensuring
                      proper management of Personal Identifiable Information
                      (PII) data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="why-our-team">
            <h1 className="project-gallery-heading text-center py-3 mt-3 m-0 text-black">
              <b>
                Why Our team for running your{" "}
                <span className="d-block">
                  brand campaigns and promotions ?
                </span>
              </b>
            </h1>
            <hr className="hr-tag" />
            <div className="pt-5 my-md-3 image-copy">
              <div className="copy w-md-75 mx-auto d-none d-md-block d-lg-none ">
                <h4 className="card-title mars-blue mars-centra-bold-font mb-3">
                  Mars In-House development team
                </h4>
              </div>
              <div className="row align-items-center">
                <div className="col-md-6 col-12 text-center">
                  <div className="w-40 mx-auto">
                    <StaticImage
                      className="image"
                      src="../images/clean-house.png"
                      alt="clean house"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-4 pt-md-0">
                  <div className="copy w-md-75">
                    <h4 className="d-block d-md-none d-lg-block card-title mars-blue mars-centra-bold-font mb-3">
                      Mars In-House{" "}
                      <span className="d-block">development team</span>
                    </h4>
                    <p className="mars-centra-font">
                      Leveraging best practices and compliance to all Mars
                      Security standards and guidelines.
                    </p>
                    <p className="mars-centra-font">
                      Managed service model where we own end-to-end delivery and
                      operations of your campaigns and promotions pre and post
                      go-live.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 my-md-3 image-copy">
              <div className="copy w-md-75 mx-auto d-none d-md-block d-lg-none ">
                <h4 className="card-title mars-blue mars-centra-bold-font mb-3">
                  Faster Go-live with solution accelerators
                </h4>
              </div>
              <div className="row flex-column-reverse flex-md-row align-items-center image-copy">
                <div className="col-md-6 col-12 pt-4 pt-md-0">
                  <div className="copy w-md-75 ms-auto">
                    <h4 className="d-block d-md-none d-lg-block card-title mars-blue mars-centra-bold-font mb-3">
                      Faster Go-live with{" "}
                      <span className="d-block">solution accelerators</span>
                    </h4>
                    <p className="mars-centra-font">
                      Plug and play solution accelerators resulting faster
                      go-live to consumers.
                    </p>
                    <p className="mars-centra-font">
                      Configurable modules - Age Gate, Digital footers, Contact
                      us form, SFMC platform integration, user registration,
                      luck draw algorithms.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-12 text-center">
                  <div className="w-40 mx-auto">
                    <StaticImage
                      className="image"
                      src="../images/alarm-clock.png"
                      alt="alarm clock"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 my-md-3 image-copy">
              <div className="copy w-md-75 mx-auto d-none d-md-block d-lg-none ">
                <h4 className="card-title mars-blue mars-centra-bold-font mb-3">
                  Huge Cost savings with better results
                </h4>
              </div>
              <div className="row align-items-center image-copy">
                <div className="col-md-6 col-12 text-center">
                  <div className="w-40 mx-auto">
                    <StaticImage
                      className="image"
                      src="../images/money.png"
                      alt="money"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-4 pt-md-0">
                  <div className="copy w-md-75">
                    <h4 className="d-block d-md-none d-lg-block card-title mars-blue mars-centra-bold-font mb-3">
                      Huge Cost savings{" "}
                      <span className="d-block">with better results</span>
                    </h4>
                    <p className="mars-centra-font">
                      Highly cost effective as compared to external agencies
                      bringing direct cost savings.
                    </p>
                    <p className="mars-centra-font">
                      Cost model is based on actual efforts and cross charged
                      within Mars.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 my-md-3 image-copy">
              <div className="copy w-md-75 mx-auto d-none d-md-block d-lg-none ">
                <h4 className="card-title mars-blue mars-centra-bold-font mb-3">
                  1st Party Data and Adoption analytics
                </h4>
              </div>
              <div className="row flex-column-reverse flex-md-row align-items-center image-copy">
                <div className="col-md-6 col-12 pt-4 pt-md-0">
                  <div className="copy w-md-75 ms-auto">
                    <h4 className="d-block d-md-none d-lg-block card-title mars-blue mars-centra-bold-font mb-3">
                      1st Party Data and{" "}
                      <span className="d-block">Adoption analytics</span>
                    </h4>
                    <p className="mars-centra-font">
                      Direct access to 1st party consumer data for your campaign
                      and promotions adoption analytics.
                    </p>
                    <p className="mars-centra-font">
                      All first party data captured aligned to global GDPR
                      compliance and integrated with Mars Sales Force Marketing
                      Cloud.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-12 text-center">
                  <div className="w-40 mx-auto">
                    <StaticImage
                      className="image"
                      src="../images/search.png"
                      alt="search"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 my-md-3 image-copy">
              <div className="copy w-md-75 mx-auto d-none d-md-block d-lg-none ">
                <h4 className="card-title mars-blue mars-centra-bold-font mb-3">
                  Agile and DevOps tools
                </h4>
              </div>
              <div className="row align-items-center image-copy">
                <div className="col-md-6 col-12 text-center">
                  <div className="w-40 mx-auto">
                    <StaticImage
                      className="image"
                      src="../images/agile.png"
                      alt="agile"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 pt-4 pt-md-0">
                  <div className="copy w-md-75">
                    <h4 className="d-block d-md-none d-lg-block card-title mars-blue mars-centra-bold-font mb-3">
                      Agile and DevOps tools
                    </h4>
                    <p className="mars-centra-font">
                      Quick turnaround time for development and go-live with
                      Agile methodology and DevOps tools.
                    </p>
                    <p className="mars-centra-font">
                      Recent promotion /campaign websites delivered on an
                      average timelines of 3-4 weeks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="our-team py-3 mt-3 m-0">
            <h1 className="project-gallery-heading mars-blue mars-centra-bold-font border-left-mars-teal text-start my-3 my-md-4 ps-2">
              <b>Digital Assets Delivery Team</b>
            </h1>

            <div className="row justify-content-center g-3 px-2 mt-3">
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "#AA58CC" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Lieu.jpg"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">
                      Architecture & Deployment Manager
                    </p>
                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Phuoc Lieu
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:phuoc.lieu@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "grey" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Tony.jpg"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">
                      Web Architecture and Deployment Lead
                    </p>
                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Anthony Bojocan
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:anthony.bojocan1@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "#c90c0c91" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Tahameem.jpg"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">
                      Web Integration Lead
                    </p>
                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Tahameem Shaik
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:tahameem.shaik@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "#599959" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Guilherme.jpg"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">Web Developer</p>

                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Guilherme Ehrat Zils
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:guilherme.ehrat.zils@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "chocolate" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Picture5.png"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">Delivery Manager</p>

                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Vamsi Krishna Reddy
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:vamsi.krishna.reddy1@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "#0fa38d" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Picture6.png"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">Tech Lead</p>

                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Arun Reddy Katta
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:arun.reddy.katta@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12 px-0">
                <div
                  className="card h-100 overflow-hidden member-card me-md-3 mb-md-3"
                  style={{ background: "#3e023c9c" }}
                  // style={{ background: "#FF4D00" }}
                >
                  <div className="image-section">
                    <StaticImage
                      className="member-photo"
                      src="../images/Picture7.jpg"
                      alt="team member"
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="card-body description text-white px-3 pt-3 pb-0">
                    <p className="mars-centra-font mb-1">Business Analyst</p>

                    <h4 className="d-block card-title mars-centra-bold-font mb-0 bg-transparent">
                      Rohith Saipinapala
                    </h4>
                  </div>
                  <div className="pb-lg-5 pb-4 px-4 text-end">
                    <a href="mailto:saipinapala.rohith@effem.com">
                      <i
                        class="fa fa-envelope"
                        style={{ fontSize: "25px", color: "white" }}
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </Layout>
  )
}
